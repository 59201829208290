<template>
  <MobileLayoutsHome>
    <template #header>
      <ClientOnly>
        <Banner />
      </ClientOnly>
    </template>
    <template #main>
      <ClientOnly>
        <div class="home" :style="getHeight">
          <div class="game-wrapper">
            <div class="game-wrapper__content" :class="{ 'margin-item': marginItem }">
              <div id="target_element" class="game-wrapper__content--left">
                <div ref="listRef" class="group-tab">
                  <div
                    v-for="tab in TABS"
                    ref="itemRef"
                    :key="tab.key"
                    class="tab-link"
                    :class="[
                      tab.key,
                      {
                        active: tab.key === route.fullPath || (tab.key === 'game/game-hot' && !route.fullPath)
                      }
                    ]"
                    @click="handleRedirectPage(tab)"
                  >
                    <BaseImg
                      :src="
                        tab.key === route.fullPath || (tab.key === 'game/game-hot' && route.fullPath)
                          ? tab.iconImage
                          : tab.iconImageDefault
                      "
                    />
                    <div>{{ tab.title }}</div>
                  </div>
                </div>
              </div>
              <div ref="listRightRef" class="game-wrapper__content--right">
                <SportMb v-if="route.fullPath === '/the-thao'" />
                <nuxt-page v-else />
              </div>
            </div>
          </div>
        </div>
      </ClientOnly>
    </template>
  </MobileLayoutsHome>
</template>

<script setup lang="ts">
import Banner from '~/components/mobile/pages/home/banner/index.vue'
import { getLeftMenu } from '~/resources/menu-left'
import MobileLayoutsHome from '~/components/mobile/layouts/home.vue'
import SportMb from '~/components/mobile/pages/the-thao/index.vue'

const route = useRoute()
const router = useRouter()
const { TABS } = getLeftMenu()
const listRef = ref(null)
const itemRef = ref<(HTMLElement | null)[]>([])
const listRightRef = ref(null)
const marginItem = ref(false)
const { $device } = useNuxtApp()

const scrollToActive = (delay = 50, index: number) => {
  setTimeout(() => {
    if (itemRef.value && itemRef.value[index]) {
      const item = itemRef.value[index] as HTMLElement | null
      if (item) {
        if (index < 4) {
          itemRef.value[0]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        } else {
          item.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }
    }
  }, delay)
}

const getHeight = computed(() => {
  const androidHeight = `${window.innerHeight - (272 / 375) * window.innerWidth}px`
  const iOSHeight = `${document.documentElement.clientHeight - (272 / 375) * document.documentElement.clientWidth}px`
  return {
    height: $device.isAndroid ? androidHeight : iOSHeight
  }
})

watch(
  route,
  () => {
    TABS.find((e, index) => {
      if (e.key === (useRoute()?.query?.game || '')) {
        scrollToActive(150, index)
        return true
      }
      return false
    })
  },
  {
    immediate: true
  }
)

const handleRedirectPage = (tab: any) => {
  router.push(tab.key)
}

watch(
  route,
  () => {
    if (route.fullPath === '/') {
      router.push('/game/game-hot')
    }
  },
  {
    immediate: true
  }
)

const isIOS = () => {
  const ua = navigator.userAgent
  return /iPhone|iPad|iPod/i.test(ua)
}

onMounted(() => {
  if (isIOS()) {
    const bodyElement = document.querySelector('body')
    if (bodyElement !== null) {
      bodyElement.classList.add('fix-screen')
    }
  }
})

onBeforeUnmount(() => {
  if (isIOS()) {
    const bodyElement = document.querySelector('body')
    if (bodyElement !== null) {
      bodyElement.classList.remove('fix-screen')
    }
  }
})
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/index.scss"></style>
